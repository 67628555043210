/* src/styles/App.module.css */
.app {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #fafafa;
}

.header {
  padding: 20px;
  border-bottom: 1px solid #dbdbdb;
}
