/* src/styles/PhotoCard.module.css */
.photoCard {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  aspect-ratio: 4 / 3; /* Maintains a 4:3 aspect ratio for each card */
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image scales without distortion */
}

.info {
  padding: 8px;
}

.caption {
  font-weight: bold;
  margin-bottom: 8px;
}

