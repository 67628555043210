/* src/styles/PhotoGrid.module.css */
.photoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 16px;
  padding: 16px;
}

/* Media query for wider screens, typically 1280px or more */
@media (min-width: 1280px) {
  .photoGrid {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }
}

/* Media query for extra-wide screens, 1920px or more */
@media (min-width: 1920px) {
  .photoGrid {
    grid-template-columns: repeat(auto-fill, minmax(750px, 1fr));
  }
}
